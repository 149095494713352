<template src="./details.html"></template>

<script>
import Http from '@/shared/http-config'
import Editor from '@tinymce/tinymce-vue';
import Usp from '../Usp'
import tinymce from '@/shared/tinymce'
import DisplayWebsite from '@/shared/DisplayWebsite'

export default {
  name: "Details",
  components: {'tinymceEditor': Editor, DisplayWebsite},
  mixins: [Usp, tinymce],
  data: function () {
    return {
      usp: {
        id: 0,
        title: "",
        description: "",
        url: "",
        url_target_new: true,
        icon: "",
        active: true,
        display_website: 7,
        type: 1
      },
      icons: [
        'icon-news',
        'icon-team',
        'icon-intensivkurs',
        'icon-service',
        'icon-finanzierung',
        'icon-toplearning',
        'icon-b',
        'icon-pkw',
        'icon-calendar-7-days',
        'icon-calendar-daily',
        'icon-learn-on-demand',
        'icon-practical-test',
        'icon-simulator',
        'icon-starter-packet',
        'icon-theoretical-test',
        'icon-smartphone',
        'icon-bike',
      ],
    }
  },
  created: function(){
    let self = this;
    if(parseInt(self.$route.params.id) > 0){
      Http.request('GET', '/usp/' + self.$route.params.type + '/' + self.$route.params.id).then((usp) => {
        self.usp = usp.data;
        self.usp.type = self.$route.params.type;
      });
    }
  },
  methods: {
    setDisplayWebsite (value){
      this.usp.display_website = value;
    },
    save(){
      let self = this;
      if(self.usp.description === ''){
        self.$bvToast.toast('Bitte gib eine Beschreibung ein', {
          title: 'Pflichtfelder',
          variant: 'danger',
          autoHideDelay: 5000
        })
      }
      else {
        Http.request('POST', '/usp/' + self.usp.type + '/' + self.usp.id, self.usp).then(() => {
          self.$router.push('/data/usp');
        });
      }
    },
  }
}
</script>

<style scoped>
@import "https://static.fahrschul-system.de/templates/website_2021/fonts/style.css";
.gecco-icon {
  font-size: 40px;
  top: -20px;
  position: relative;
}
</style>
