<template>
  <b-row class="display-website" no-gutters>
    <b-col cols="auto" v-for="language in languages" v-bind:key="language.value">
      <b-button variant="outline-primary"
                class="btn-language"
                v-if="language.value & show"
                :class="(value & language.value ? 'active' : '')"
                v-on:click="setDisplayWebsite(language.value)">
        <b-img :src="language.icon"></b-img><br>
        {{ language.name }}
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
  export default {
    name: "DisplayWebsite",
    props: {
      value: {
        default: 0
      },
      show: {
        default: 7
      },
    },
    data: function () {
      return {
        languages: [
          {
            value: 1,
            name: 'Auto',
            icon: 'https://files.fahrschul-system.de/1_0/klasseb.png'
          },
          {
            value: 2,
            name: 'Motorrad',
            icon: 'https://files.fahrschul-system.de/1_0/klassea.png'
          },
          {
            value: 4,
            name: 'BKF',
            icon: 'https://files.fahrschul-system.de/1_0/klassec.png'
          },
        ],
      }
    },
    methods: {
      setDisplayWebsite (value){
        value = parseInt(value);
        let new_value = parseInt(this.value);

        if(new_value & value){
          new_value = new_value - value;
        }
        else{
          new_value = new_value + value;
        }

        this.$emit('change', new_value);
      },
    }
  }
</script>

<style>
  .display-website .col-auto {
    margin-left: 12px;
  }
  .display-website .col-auto:nth-child(1) {
    margin-left: 0px;
  }
</style>
<style scoped>
  .btn-language {
    padding: 0.5rem;
  }
  .btn-language img {
    max-height: 38px;
    max-width: 90px;
    margin-bottom: 4px;
  }
  @media (min-width: 992px){
    .btn-language {
      padding: 0.75rem;
    }
  }
</style>
