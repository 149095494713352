import Http from './http-config'

export default {
  data: function(){
    let self = this;
    return {
      tinymce: {
        key: process.env.VUE_APP_TINYMCE_KEY,
        defaultOptions: {
          height: 300,
          menubar: false,
          plugins: 'image link media imagetools lists',
          toolbar: 'undo redo | bold italic underline strikethrough | fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | numlist bullist | outdent indent | removeformat | image link',
          language: 'de',
          language_url: 'https://static.fahrschul-system.de/tinymce/langs/de.js',
          default_link_target: "_blank",
          file_picker_callback: self.filePicker,
          automatic_uploads: true,
          images_upload_handler: self.upload,
          imagetools_toolbar: "editimage imageoptions"
        },
      }
    }
  },
  methods: {
    upload: function (blobInfo, success, failure) {
      let formData = new FormData();
      formData.append('file', blobInfo.blob(), blobInfo.filename());

      Http.request('POST', '/tinymce/upload', formData).then(function (response) {
          success(response.data);
        })
        .catch(function (data) {
          failure('Beim Upload ist ein Fehler aufgetreten');
        });
    },
    filePicker: function(callback, value, meta) {
      let input = document.createElement('input');
      input.setAttribute('type', 'file');
      if (meta.filetype === 'image') {
        input.setAttribute('accept', 'image/*');
      }
      input.onchange = function () {
        let file = this.files[0];
        let reader = new FileReader();

        reader.onload = function () {
          let id = 'blobid' + (new Date()).getTime();
          let blobCache =  tinymce.activeEditor.editorUpload.blobCache;
          let base64 = reader.result.split(',')[1];
          let blobInfo = blobCache.create(id, file, base64);

          let formData = new FormData();
          formData.append('file', blobInfo.blob(), file.name);

          Http.request('POST', '/tinymce/upload', formData).then(function (response) {
              callback(response.data, { title: file.name });
            })
            .catch(function (data) {
              alert('Beim Upload ist ein Fehler aufgetreten');
            });
        };
        reader.readAsDataURL(file);
      };
      input.click();
    }
  }
}
